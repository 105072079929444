import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { InsightsService } from 'src/app/services/insights.service';
import { AppSessionStorage } from 'src/app/utils/app-session-storage-utils';
import { Utility } from 'src/app/utils/app-utils';

interface DataPoint {
  time: string;
  count: number;
  percentage?: number;
}

interface ChartData {
  chart: string;
  today: DataPoint[];
  previous_day: DataPoint[];
}

@Component({
  selector: 'app-realtime-alarms',
  templateUrl: './realtime-alarms.component.html',
  styleUrls: ['./realtime-alarms.component.scss']
})

export class RealtimeAlarmsComponent
  implements AfterViewInit, AfterViewChecked {

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService) { }
  dynamicWidth: number = 300;
  dynamicHeight: number = 180;
  slotMinutes: number = 20;
  liveUpdates: Subscription;

  data: ChartData = {
    "chart": "realtime-alarms",
    "today": [
      { "time": "00:00", "count": 0 }
    ],
    "previous_day": [
      { "time": "00:00", "count": 0 },
    ]
  };

  ngAfterViewInit(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  onToggleChange(event: any): void {
    this.slotMinutes = event.value;
    this.getIncidents();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  async ngOnInit() {
    await this.getIncidents();
    setInterval(async () => {
      await this.getIncidents();
    }, 300000); // 300000 milliseconds = 5 minutes

    this.graphUpdateListener();
  }

  async graphUpdateListener() {
    this.liveUpdates = this.insightsService.updateIncidents().subscribe(async (update: any) => {
      if (update === 'newIncident') {
        await this.getIncidents();
      }
    });
  }

  async getIncidents() {
    let res = await this.insightsService.getLiveIncidents(this.slotMinutes);
    if (res && res.success && res.data && res.data.results) {
      const user = AppSessionStorage.getUser();
      const timeZone = user.timeZone;
      this.calculateChartValues(res.data.results, timeZone);
    } else { }
  }

  private calculateChartValues(values: any, timeZone: string) {
    const today_iso = new Date();
    let today = new Date(today_iso.setHours(0, 0, 0, 0));
    let previous_date_last_week = new Date((new Date(today_iso)).setDate(today.getDate() - 7));

    let formattedData = {
      'previous_day_last_week': this.formatAndFillDayHours(values['previous_day_last_week'], previous_date_last_week, timeZone),
      'today': this.formatAndFillDayHours(values['today'], today, timeZone, true)
    };

    this.data = {
      "chart": this.data.chart,
      "today": formattedData.today,
      "previous_day": formattedData.previous_day_last_week
    };

    this.cdr.detectChanges();
  }

  private updateSvgDimensions() {
    const parentWidth = document
      .querySelector('#line-realtime-alarms')
      ?.getBoundingClientRect().width;
    if (parentWidth) {
      this.dynamicWidth = parentWidth ? parentWidth - 30 : 300;
    }

    this.dynamicHeight = window.innerHeight > 1300 ? 285
      : window.innerHeight >= 1080 ? 220
        : window.innerHeight >= 1056 ? 220
          : window.innerHeight >= 1024 ? 158
            : window.innerHeight >= 934 ? 195
              : 258;
  }

  private formatAndFillDayHours(value: any, day: Date, timeZone: string, percentage_key: boolean = false) {
    let formatted = [];
    let slotMinutesTimestamp = this.slotMinutes * 60 * 1000;
    let dayEnd = Number(new Date(day).setHours(23, 59, 59, 999));
    let day_timestamp_start = Number(new Date(day).setHours(0, 0, 0, 0));
    let nowTimestamp = Date.now();

    for (let i = day_timestamp_start; i < dayEnd; i += slotMinutesTimestamp) {
      if (i > nowTimestamp) break;

      const localTime = new Date(i).toLocaleString('en-US', {
        timeZone: timeZone,
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      });

      let detail = { "time": localTime, count: 0 };
      if (percentage_key) {
        detail["percentage"] = 0;
      }

      if (value[i]) {
        let data = value[i];
        detail["count"] = data["count"];
        if (percentage_key) {
          detail["percentage"] = data["percentage"];
        }
      }

      formatted.push(detail);
    }

    return formatted;
  }

  getLegends(): string[] {
    const today = 'Today';
    const previousDayLabel = this.getPreviousDayLabel(7);
    return [today, previousDayLabel];
  }

  getPreviousDayLabel(dayOffset: number): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // Get today's date
    const today = new Date();
    // Calculate the previous day by subtracting the offset
    const previousDate = new Date(today);
    previousDate.setDate(today.getDate() - dayOffset);
    const dayName = daysOfWeek[previousDate.getDay()];
    return `Last ${dayName}`;
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.liveUpdates);
  }
}
